<template>
	<el-dialog :title="!dataForm.name ? '请假记录添加' : '请假记录修改'" :close-on-click-modal="false" :visible.sync="visible"
		width="500px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="人员" prop="workMan" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.workMan" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="请假日期" prop="beginTime" class="item">
						<el-date-picker class="selItemInput" v-model="dataForm.beginTime" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="天数" prop="leaveDays" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.leaveDays"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "leavetime-add-or-update",
	data() {
		return {
			addImg: addImg,
			id: "",
			visible: true,
			form: {},
			action: "",
			meetingTypeList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				workMan: "",
				leaveDays: "",
				beginTime: ""
			},
			dataRule: {
				workMan: [
					{ required: true, message: " ", trigger: "blur" }
				],
				beginTime: [
					{ required: true, message: " ", trigger: "blur" }
				],
				leaveDays: [
					{ required: true, message: " ", trigger: "blur" }
				]
			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				workMan: "",
				leaveDays: "",
				beginTime: ""
			};
			this.dataForm.id = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/leaveinfo/info/" + this.dataForm.id,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
				}
			});
		},
		dataFormSubmit() {
			if (this.dataForm.workMan == null || this.dataForm.workMan == "") {
				this.$message.error("请假人员不能为空！");
				return;
			}
			if (this.dataForm.beginTime == null || this.dataForm.beginTime == "") {
				this.$message.error("请假日期不能为空！");
				return;
			}
			if (this.dataForm.leaveDays == null || this.dataForm.leaveDays == "") {
				this.$message.error("请假天数不能为空！");
				return;
			}
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/leaveinfo/save";
					if (this.dataForm.id && this.dataForm.id != 0) {
						_url = "/business/leaveinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
